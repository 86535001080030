import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "./Pages/Home";
import IntakePortal from "./Pages/IntakePortal";
import NewsPage from "./Pages/News";
import NewsDetails from "./Components/NewsDetails";
import NewsCategory from "./Components/NewsCategory";
import Login from "./Components/Login"; // Import the Login component
import GAnalyticsTracker from "./Components/GTM";
import Footer from "./Components/Footer";
import NavBar from "./Components/Navbar";

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
    
    useEffect(() => {
      // Scroll to the top of the page whenever the route changes
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  // State to manage user authentication
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to handle login
  const handleLogin = () => {
    // Perform authentication logic here, e.g., using Axios to send login request
    // If authentication succeeds, set isAuthenticated to true
    setIsAuthenticated(true);
  };

  // Function to handle logout
  const handleLogout = () => {
    // Perform logout logic here, e.g., clearing localStorage
    setIsAuthenticated(false);
  };

  return (
    <div className="App">
      <GAnalyticsTracker />
      <BrowserRouter>
      <NavBar />
        <ScrollToTop />
        <div className="content">
          <Routes>
            {/* Render the Login component if not authenticated */}
            {!isAuthenticated && <Route path="/login" element={<Login onLogin={handleLogin} />} />}
            {/* Render the IntakePortal component if authenticated, otherwise redirect to login */}
            {isAuthenticated ? (
              <Route path="/intake" element={<IntakePortal />} />
            ) : (
              <Route path="/intake" element={<Login onLogin={handleLogin} />} />
            )}

            <Route index element={<NewsPage />} />
            <Route path="/" element={<Home />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/category/:category" element={<NewsCategory />} />
            <Route path="/:category/:id" element={<NewsDetails />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar ,NavLink} from "react-bootstrap";
import my_logo from "../Images/my_logo.jpg";
import { Link } from "react-router-dom";
import axios from "axios";
import { SERVER_DOMAIN } from "../constant/config";

const NavBar = () => {
  const [newsCategory, setNewsCategory] = useState([]); // State to store the news item details
  useEffect(() => {
    // Fetch news data from the backend API
    axios
    .get(`${SERVER_DOMAIN}/api/news`)
    .then((response) => {
      setNewsCategory(response.data);
      console.log(response.data)
    })
    .catch((error) => {
      console.error("Error fetching news data:", error);
    });
    
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Navbar expand="lg" className="bg-black">
        <Container>
          <Navbar.Brand
            as={Link}
            to="/"
            onClick={handleClick}
            className="text-white fw-500 p-0"
          >
            <img
              src={my_logo}
              alt="TOP!NEWS"
              className="img-fluid"
              style={{
                width: "auto",
                height: "70px",
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="text-white border-white"
          />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              {newsCategory.filter((item, index, self) => index === self.findIndex((t) => t.NE_Category === item.NE_Category)).map((newsItem) => (
                <Nav.Link as={Link} key={newsItem.NE_ID} className="text-white fw-bold" to={`/category/${newsItem.NE_Category}`}>
                {newsItem.NE_Category}
              </Nav.Link>
              ))}
              {/* <Nav.Link as={Link} className="text-white fw-bold" to={"/news"}>
                NEWS
              </Nav.Link> */}
              {/* <Nav.Link as={Link} className="text-white fw-bold" to={"/intake"}>
                Intake
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Footer = () => {
  return (
    <footer className="footer d-flex justify-content-center align-items-center mt-auto py-1 bg-black text-white">
      <div className="container">
        <div className="row text-center">
          <div className="col-12 col-md-4">
            <p className="mb-0" style={{ fontSize: "smaller" }}>Top 1 News</p>
          </div>
          <div className="col-12 col-md-4">
            <p className="mb-0" style={{ fontSize: "smaller" }}>Maniyattukudi Asfa Building, IG Road, Calicut</p>
          </div>
          <div className="col-12 col-md-4">
            <p className="mb-0" style={{ fontSize: "smaller" }}>
              Contact:{" "}
              <a href="mailto:top1newschannel@gmail.com" className="text-white">
                top1newschannel@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className="row text-center mt-1">
          <div className="col-12">
            <div className="text-muted mb-0" style={{ fontSize: "smaller" }}>
              <small>&copy; {new Date().getFullYear()} Top 1 News. All rights reserved.</small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

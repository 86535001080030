import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import NavBar from "../Components/Navbar";
import { SERVER_DOMAIN } from "../constant/config";
// import LoadingSpinnerPopup from "../Components/LoadingSpinnerPopup";
// import "styles.css"
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import { Container } from "react-bootstrap";
import './styles.css';

const NewsForm = () => {
  const [formData, setFormData] = useState({
    NE_Category: "",
    NE_Status: "",
    NE_Priority: "",
    NE_Title: "",
    NE_Lead: "",
    NE_Description: "",
    NE_City: "",
    NE_State: "",
    NE_Country: "",
    NE_Postalcode: "",
    NE_YoutubeLink: "",
    NE_Image: ""
  });
  const [formErrors, setFormErrors] = useState({
    NE_Title: "",
    NE_Description: "",
    NE_Image: ""
  });
  const toastId = React.useRef(null);
  const [selectedNewsItem, setSelectedNewsItem] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [AdimageFile, setAdImageFile] = useState(null);
  const [newsItems, setNewsItems] = useState([]);
  const [ads, setAds] = useState([]);
  const [adFormData, setAdFormData] = useState({
    place: "",
    status: ""
  });
  const adFileInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [selectedAd, setSelectedAd] = useState(null)
  const commonCategories = ["GENERAL","SPORTS", "POLITICS", "BUSINESS", "ENTERTAINMENT", "TECHNOLOGY", "HEALTH"];
  const NewsStatus = ["ACTIVE", "DEACTIVATE"];
  const adPlaces = ["AllPages","HomePage", "CategoryPage", "NewsPage"];
  const adStatus = ["ACTIVE", "INACTIVE"];

  useEffect(() => {
    fetchNewsItems();
    fetchAds();
    const adjustTextareaHeight = () => {
      const textarea = document.querySelector('.auto-resize-textarea');
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    };

    // Attach event listener for input changes
    const textarea = document.querySelector('.auto-resize-textarea');
    textarea.addEventListener('input', adjustTextareaHeight);

    // Clean up
    return () => {
      textarea.removeEventListener('input', adjustTextareaHeight);
    };
  }, [formData.NE_Description]);

  const bounce = cssTransition({
    enter: "animate__animated animate__bounceIn",
    exit: "animate__animated animate__bounceOut"
  });

  const youtubeUrlRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/

  const validateYoutubeLink = (link) => {
    if (link.trim() === '') {
      return true;
    }
    return youtubeUrlRegex.test(link);
  }

  const notifyPop = () => toastId.current = toast("In progress. Please wait!", {
    transition: bounce,
    autoClose: false,
    position: "bottom-right",
    hideProgressBar: false,
    closeButton: false,
    theme: "dark"
  });

  const updatePop = (text) => toast.update(toastId.current, {
    render: text,
    autoClose: 5000,
    closeButton: null,
    transition: bounce
  });
  const addPop = () => toast.update(toastId.current, {
    render: "News Added!",
    autoClose: 5000,
    closeButton: null,
    transition: bounce
  });
  const deletePop = () => toast.update(toastId.current, {
    render: "News Deleted!",
    autoClose: 5000,
    closeButton: null,
    transition: bounce
  });
  const errorPop = () => toast.update(toastId.current, {
    render: "Error!",
    autoClose: 5000,
    closeButton: null,
    transition: bounce
  });
  const fieldsErrorPop = () => toast.update(toastId.current, {
    render: "Please fill in all required fields!",
    autoClose: 5000,
    closeButton: null,
    transition: bounce
  });
  const invalidYoutubeLinkPop = () => toast.update(toastId.current, {
    render: "Invalid youtube link!",
    autoClose: 5000,
    closeButton: null,
    transition: bounce
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleAdImageChange = (e) => {
    setAdImageFile(e.target.files[0]);
  };

  const handleAdPlaceChange = (e) => {
    setAdFormData({ ...adFormData, place: e.target.value });
  };

  const handleAdStatusChange = (e) => {
    setAdFormData({ ...adFormData, status: e.target.value });
  };

  const fetchNewsItems = async () => {
    try {
      const response = await axios.get(`${SERVER_DOMAIN}/api/news`);
      const newsEntries = response.data;
      // const filteredEntries = newsEntries.filter(entry => entry.NE_Status !== 'DEACTIVATE');
      setNewsItems(newsEntries);
    } catch (error) {
      console.error("Error fetching news items:", error);
    }
  };

  const fetchAds = async () => {
    try {
      const response = await axios.get(`${SERVER_DOMAIN}/api/ads`);
      setAds(response.data);
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    notifyPop()
    const youtubeLink = formData.NE_YoutubeLink;
    if (validateYoutubeLink(youtubeLink)) {
      console.log("Valid YouTube link.");
    } else {
      invalidYoutubeLinkPop()
      console.log("Invalid YouTube link.");
      return;
    }
    console.log(formData.NE_Description);
    if (selectedNewsItem) console.log(selectedNewsItem) 
    else if (!formData.NE_Title ||
      !formData.NE_Description ||
      !imageFile
    ) {
      fieldsErrorPop()
      return;
    }
    // const errors = {};
    // if (!formData.NE_Title) {
    //   errors.NE_Title = "Please enter a title";
    // }
    // if (!formData.NE_Description) {
    //   errors.NE_Description = "Please enter a description";
    // }
    // if (!imageFile) {
    //   errors.NE_Image = "Please select an image";
    // }
    // if (Object.keys(errors).length > 0) {
    //   setFormErrors(errors);
    //   return;
    // }
    const formDataWithImage = new FormData();

    for (const key in formData) {
      if (formData.hasOwnProperty(key) && key !== 'NE_CreatedAt' && key !== 'NE_UpdatedAt') {
        let value = formData[key];
        if (key === 'NE_Country' && !value) {
          value = 'India';
        }
        formDataWithImage.append(key, value);
      }
    }
    if (imageFile) {
      console.log(imageFile)

      formDataWithImage.append("NE_Image", imageFile);
    }
    try {
      if (selectedNewsItem) {
        const response = await axios.put(
          `${SERVER_DOMAIN}/api/events/${selectedNewsItem.NE_ID}`,
          formDataWithImage,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        updatePop("News Updated")
        console.log(response.data);
      } else {
        // Adding a new news item
        const response = await axios.post(
          `${SERVER_DOMAIN}/api/events`,
          formDataWithImage,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        addPop()
      }

      // Reset the form after successful submission
      setFormData({
        NE_Category: "",
        NE_Status: "",
        NE_Priority: "",
        NE_Title: "",
        NE_Lead: "",
        NE_Description: "",
        NE_City: "",
        NE_State: "",
        NE_Country: "",
        NE_Postalcode: "",
        NE_YoutubeLink: "",
        NE_Image: ""
      });
      fileInputRef.current.value = null;
      setImageFile(null);
      fetchNewsItems();
      // setLoading(false);
    } catch (error) {
      errorPop()
      console.error("Error:", error);
    }
  };

  const handleEdit = (newsItem) => {
    setSelectedNewsItem(newsItem);
    setFormData(newsItem); // Populate form fields with selected news item data
  };

  const handleDelete = async (id) => {
    try {
      // setLoading(true);
      notifyPop();
      const response = await axios.delete(`${SERVER_DOMAIN}/api/events/${id}`);
      console.log(response.data);
      fetchNewsItems();
      updatePop("News Deleted!");
    } catch (error) {
      errorPop();
      console.error("Error:", error);
    }
  };

  const handleAdSubmit = async (e) => {
    e.preventDefault();
    notifyPop();
    if (!adFormData.place || !adFormData.status) {
      fieldsErrorPop();
      return;
    }
    const formAdDataWithImage = new FormData();
    formAdDataWithImage.append("place", adFormData.place);
    formAdDataWithImage.append("status", adFormData.status);
    formAdDataWithImage.append("adImage", AdimageFile);
    console.log(adFormData.place)
    try {
      if(selectedAd){
        const response = await axios.put(
          `${SERVER_DOMAIN}/api/ads/${selectedAd.id}`,
          formAdDataWithImage,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        updatePop("Ad Updated");
        setSelectedAd(null)
      }else{
        const response = await axios.post(
          `${SERVER_DOMAIN}/api/ads`,
          formAdDataWithImage,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        updatePop("Ad Added!");
      }
      setAdFormData({
        place: "",
        status: ""
      });
      adFileInputRef.current.value = null;
      fetchAds();
    } catch (error) {
      errorPop();
      console.error("Error:", error);
    }
  };

  const handleAdEdit = (ad) => {
    setSelectedAd(ad);
    setAdFormData(ad);
  };

  const handleAdDelete = async (id) => {
    try {
      notifyPop();
      const response = await axios.delete(`${SERVER_DOMAIN}/api/ads/${id}`);
      fetchAds();
      updatePop("Ad Deleted!");
    } catch (error) {
      errorPop();
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Container>
        <h2>{selectedNewsItem ? "Edit Event" : "Add Event"}</h2> {/* Update heading based on whether editing or adding */}
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col-lg-3">
              <label htmlFor="NE_Category" className="form-label">
                NE Category
              </label>
              <select
                name="NE_Category"
                value={formData.NE_Category}
                onChange={handleChange}
                className="form-select"
                required
              >
                <option value="">Select a category</option>
                {commonCategories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-3">
              <label htmlFor="NE_Status" className="form-label">
                NE Status
              </label>
              <select
                name="NE_Status"
                value={formData.NE_Status}
                onChange={handleChange}
                className="form-select"
                required
              >
                <option value="">Select Status</option>
                {NewsStatus.map((status, index) => (
                  <option key={index} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-3">
              <label htmlFor="NE_Priority" className="form-label">
                NE Priority
              </label>
              <input
                type="number"
                name="NE_Priority"
                value={formData.NE_Priority}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-5">
              <label htmlFor="NE_Title" className="form-label">
                News Title
              </label>
              <input
                type="text"
                name="NE_Title"
                value={formData.NE_Title}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="col-lg-7">
              <label htmlFor="NE_Lead" className="form-label">
                News Lead
              </label>
              <input
                type="text"
                name="NE_Lead"
                value={formData.NE_Lead}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-5">

              {selectedNewsItem ?
                <>
                  <label htmlFor="NE_Image" className="form-label">
                    News Image
                  </label> <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    className="form-control"
                  />
                </>
                :
                <>
                  <label htmlFor="NE_Image" className="form-label">
                    News Image
                  </label>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    className="form-control"
                    required
                  />
                </>
              }
              {/* {formErrors.NE_Image && (
            <div className="text-danger">{formErrors.NE_Image}</div>
          )} */}
            </div>
            <div className="col-lg-7">
              <label htmlFor="NE_YoutubeLink" className="form-label">
                Youtube Link
              </label>
              <input
                type="text"
                name="NE_YoutubeLink"
                value={formData.NE_YoutubeLink}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="mb-3"></div>
          <div className="mb-3">
            <label htmlFor="NE_Description" className="form-label">
              News Description
            </label>
            <textarea
              name="NE_Description"
              value={formData.NE_Description}
              onChange={handleChange}
              className="form-control auto-resize-textarea"
            />
          </div>

          <div className="row mb-3">
            <div className="col-lg-3">
              <label htmlFor="NE_City" className="form-label">
                City
              </label>
              <input
                type="text"
                name="NE_City"
                value={formData.NE_City}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="NE_State" className="form-label">
                State
              </label>
              <input
                type="text"
                name="NE_State"
                value={formData.NE_State}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="NE_Country" className="form-label">
                Country
              </label>
              <input
                type="text"
                name="NE_Country"
                placeholder="India"
                value={formData.NE_Country}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="NE_Postalcode" className="form-label">
                Postal Code
              </label>
              <input
                type="text"
                name="NE_Postalcode"
                value={formData.NE_Postalcode}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            {selectedNewsItem ? "Update News" : "Add News"} {/* Update button label based on whether editing or adding */}
          </button>
        </form>
      </Container>

      <div style={{backgroundColor: "#f4f4f6"}} className="container my-4 py-4 text-start">
            <h2>News Items</h2>
</div>
<div style={{ backgroundColor: "#f4f4f6", maxHeight: "400px", overflowY: "auto" }}>
  <div className="container my-3 text-start">
            <div className="table-responsive">
      <div style={{ overflowX: "auto" }}>
              <table className="table table-striped">
                <thead>
                  <tr>
              <th style={{ minWidth: "150px" }}>Image</th>
              <th style={{ minWidth: "200px" }}>Title</th>
              <th style={{ minWidth: "150px" }}>Category</th>
              <th style={{ minWidth: "100px" }}>Status</th>
              <th style={{ minWidth: "100px" }}>Edit</th>
              <th style={{ minWidth: "100px" }}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {newsItems.map((newsItem) => (
                    <tr key={newsItem.NE_ID}>
                <td style={{ maxWidth: "150px" }}>
                  <img
                    src={newsItem.NE_Image}
                    alt="Thumbnail"
                    className="img-fluid"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                      </td>
                <td className="text-wrap" style={{ maxWidth: "200px" }}>{newsItem.NE_Title}</td>
                <td className="text-wrap" style={{ maxWidth: "150px" }}>{newsItem.NE_Category}</td>
                <td style={{ maxWidth: "100px" }}>{newsItem.NE_Status}</td>
                <td style={{ maxWidth: "100px" }}>
                        <button className="btn btn-secondary btn-sm" onClick={() => handleEdit(newsItem)}>
                          Edit
                        </button>
                      </td>
                <td style={{ maxWidth: "100px" }}>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDelete(newsItem.NE_ID)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>



<Container className="my-4 py-4 text-start">
        <h2>Manage Ads</h2>
        <form onSubmit={handleAdSubmit}>
          <div className="row mb-3">
            <div className="col-lg-4">
              <label htmlFor="adPlace" className="form-label">
                Ad Place
              </label>
              <select
                name="adPlace"
                value={adFormData.place}
                onChange={handleAdPlaceChange}
                className="form-select"
                required
              >
                <option value="">Select Ad Place</option>
                {adPlaces.map((place, index) => (
                  <option key={index} value={place}>
                    {place}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-4">
              <label htmlFor="adStatus" className="form-label">
                Ad Status
              </label>
              <select
                name="adStatus"
                value={adFormData.status}
                onChange={handleAdStatusChange}
                className="form-select"
                required
              >
                <option value="">Select Ad Status</option>
                {adStatus.map((status, index) => (
                  <option key={index} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-4">
              <label htmlFor="adImage" className="form-label">
                Ad Image
              </label>
              {selectedAd ?
                <>
                <input
                type="file"
                ref={adFileInputRef}
                onChange={handleAdImageChange}
                className="form-control"
              />
                </>
                :
                <>
                  <input
                type="file"
                ref={adFileInputRef}
                onChange={handleAdImageChange}
                className="form-control"
                required
              />
                </>
              }
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            {selectedAd ? "Update Ad" : "Add Ad"}
          </button>
        </form>
      </Container>
      <div style={{backgroundColor: "#f4f4f6"}} className="container my-4 py-2 text-start">
  <h2>Ads</h2>
</div>
<div style={{ backgroundColor: "#f4f4f6", maxHeight: "400px", overflowY: "auto" }}>
  <div className="container my-3 text-start">
    <div className="table-responsive">
      <div style={{ overflowX: "auto" }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th style={{ minWidth: "150px" }}>Ad Image</th>
              <th style={{ minWidth: "150px" }}>Place</th>
              <th style={{ minWidth: "100px" }}>Status</th>
              <th style={{ minWidth: "100px" }}>Edit</th>
              <th style={{ minWidth: "100px" }}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {ads.map((ad) => (
              <tr key={ad.id}>
                <td style={{ maxWidth: "150px" }}>
                  <img
                    src={ad.Ad_Image_Path}
                    alt="Ad Thumbnail"
                    className="img-fluid"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </td>
                <td style={{ maxWidth: "150px" }}>{ad.place}</td>
                <td style={{ maxWidth: "100px" }}>{ad.status}</td>
                <td style={{ maxWidth: "100px" }}>
                  <button className="btn btn-secondary btn-sm" onClick={() => handleAdEdit(ad)}>
                    Edit
                  </button>
                </td>
                <td style={{ maxWidth: "100px" }}>
                  <button className="btn btn-danger btn-sm" onClick={() => handleAdDelete(ad.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default NewsForm;

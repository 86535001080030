import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const GAnalyticsTracker = () => {
  useEffect(() => {
    ReactGA.initialize('G-PJVV6QD93Y'); // Replace with your GTM ID
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

    // Event tracking example
    ReactGA.event({
      category: 'User Interaction',
      action: 'Page Load',
      label: 'Home Page',
    });

  }, []);

  return null; // This component doesn't render any UI
};

export default GAnalyticsTracker;

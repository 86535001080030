import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Col, Container, Image, Row } from "react-bootstrap";
import ReactPlayer from 'react-player';
import { SERVER_DOMAIN } from "../constant/config";
import '../styles/custom.css';

const NewsDetails = () => {
  const { id, category } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const [AdsData, setAdsData] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    axios
      .get(`${SERVER_DOMAIN}/api/news/${id}`)
      .then((response) => {
        setNewsItem(response.data);
      })
      .catch((error) => {
        console.error("Error fetching news details:", error);
      });

          // Fetch ads and set up interval
    const fetchAdsAndSetupInterval = async () => {
      try {
        const response = await axios.get(`${SERVER_DOMAIN}/api/ads`);
        const adData = response.data;
        const filteredAdData = adData.filter(entry => entry.status !== 'INACTIVE' && (entry.place === 'NewsPage' || entry.place === 'AllPages'));
        setAdsData(filteredAdData);
        console.log(filteredAdData)
        // Set interval to rotate ads every 5 seconds if there are ads available
        if (filteredAdData.length > 0) {
          const id = setInterval(() => {
            setCurrentAdIndex(prevIndex => (prevIndex + 1) % filteredAdData.length);
          }, 60000);

          // Store intervalId in state
          setIntervalId(id);
        }
      } catch (error) {
        console.error("Error fetching ads:", error);
      }
    };

    fetchAdsAndSetupInterval();

    // Cleanup function for interval
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [id]);

  if (!newsItem) {
    return <p>Loading...</p>;
  }

  // Function to convert URLs in text to clickable hyperlinks
  const renderDescriptionWithLinks = (description) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return description.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url} <i class="fas fa-external-link-alt"></i></a>`;
    });
  };

  return (
    <>
      <Container>
      <Row className="py-4">
            <Col lg={9}>
            <div>
              <p className="fw-bold text-uppercase border-start border-4 p-2" style={{ color: "red" }}>
                <small>
                  {newsItem.NE_Country} <span className="text-dark">| {newsItem.NE_Category}</span>
                </small>
              </p>
              <h2 className="fw-bold mb-3">{newsItem.NE_Title}</h2>
              <Image src={newsItem.NE_Image} className="img-fluid mb-3" alt={newsItem.NE_Title} />
              <p className="fw-bold">{newsItem.NE_Lead}</p>
              {newsItem.NE_YoutubeLink.trim() !== "" && (
                <div className="player-wrapper mb-3">
                  <ReactPlayer className="react-player" url={newsItem.NE_YoutubeLink} width="100%" height="100%" />
                </div>
              )}
              <div
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{ __html: renderDescriptionWithLinks(newsItem.NE_Description) }}
              />
            </div>
          </Col>
          <Col lg={3} className="text-center d-md-block">
              <small className="text-center pb-2">Advertisement</small>
              <Image className="mb-2" src={AdsData[currentAdIndex]?.Ad_Image_Path} fluid />
            </Col>
          </Row>
      </Container>
    </>
  );
};

export default NewsDetails;

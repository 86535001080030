import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";


const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // Function to check if token is expired
const isTokenExpired = () => {
  const token = localStorage.getItem("token");
  console.log(token)
  if (!token) return true; // Token not found

  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp * 1000 < Date.now(); // Check if token expiration time is in the past
  } catch (error) {
    // Handle decoding errors, e.g., invalid token format
    console.error("Error decoding token:", error);
    return true; // Treat as expired to be safe
  }
};

  // Function to handle login
  const handleLogin = async () => {
    try {
      const response = await axios.post(`/api/login`, { username, password });
      const token = response.data.token;
      // Store token and its expiration time in local storage
      localStorage.setItem("token", token);
      // Redirect or perform any necessary actions after successful login
      onLogin(token);
    } catch (error) {
      setError("Invalid username or password");
    }
  };

  // Function to handle authenticated request
  const handleAuthenticatedRequest = async () => {
    if (isTokenExpired()) {
      // Token is expired, prompt user to log in again
      // Alternatively, you can implement token refresh logic here
      // For simplicity, let's assume the user needs to log in again
      // setError("Session expired. Please log in again.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      // Attach token to request headers for authentication
      const response = await axios.get("/api/protected", {
        headers: { Authorization: token },
      });
      // Process response data as needed
      console.log("Data from protected endpoint:", response.data);
      onLogin();
    } catch (error) {
      // Handle unauthorized or other errors
      console.error("Error:", error);
      setError("Error fetching data from server");
    }
  };

  // Effect to check token expiration on component mount
  useEffect(() => {
    handleAuthenticatedRequest();
  }, []);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">Login</div>
            <div className="card-body">
              {error && <div className="alert alert-danger">{error}</div>}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

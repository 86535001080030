import React from "react";
import NavBar from "../Components/Navbar";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

const Home = () => {
  const events = [
    {
      id: 1,
      title:
        "99 ടെസ്റ്റ്, 507 വിക്കറ്റ്, 3309 റൺസ്, പ്ലയർ ഓഫ് ദ് മാച്ചുകളിൽ ‘ഒന്നാമൻ’; നായകനാകാൻ ഇതൊന്നും പോരെന്നോ?",
      lead:
        "ആർ. അശ്വിന്റെ 100–ാം ടെസ്റ്റ് മത്സരത്തിനാണ് ധരംശാല ഇക്കുറി വേദിയൊരുക്കുന്നത്. 2011 ഏകദിന ലോകകപ്പ് നേടിയ ഇന്ത്യൻ ടീമിലും അശ്വിൻ അംഗമായിരുന്നു.",
      date: "March 07 , 2024 08:22 AM IST",
      country: "INDIA",
      image:
        "https://img-mm.manoramaonline.com/content/dam/mm/mo/premium/life/images/2024/3/6/ravichandran-ashwin-main.jpg?w=575&h=299",
    },
    {
      id: 2,
      title:
        "ഒരാൾ പുറത്ത്, രണ്ടുപേർ കാലിൽ; പെൺസിംഹങ്ങൾക്കെതിരെ പൊരുതി തോറ്റ് കാട്ടുപോത്ത്; ദാരുണമായ കാഴ്ച",
      lead:
        "അക്രമി എത്ര ശക്തനാണെങ്കിലും സ്വന്തം ജീവൻ നഷ്ടപ്പെടുമെന്ന സാഹചര്യം വന്നാൽ ‍മനുഷ്യനാണെങ്കിലും മൃഗങ്ങളാണെങ്കിലും അവസാനം വരെ പോരാടുന്നു. അത്തരത്തിൽ തന്നെ ആക്രമിച്ചു കീഴ്‌പ്പെടുത്താൻ എത്തിയ സിംഹകൂട്ടത്തിന് മുന്നിൽ തോറ്റുകൊടുക്കാനാവാതെ പലയാവർത്തി പൊരുതിനിൽക്കുന്ന ഒരു കാട്ടുപോത്തിന്റെ ദൃശ്യങ്ങൾ സമൂഹമാധ്യമങ്ങളിൽ പ്രചരിക്കുന്നു.",
      date: "March 07 , 2024 08:22 AM IST",
      country: "INDIA",
      image:
        "https://img-mm.manoramaonline.com/content/dam/mm/mo/environment/environment-news/images/2024/3/7/animal-viral.jpg",
    },
    {
      id: 3,
      title:
        "ചൈനയെ ഭയപ്പെടുത്താന്‍ ഇന്ത്യയുടെ സ്വന്തം സൊറാവർ; ലഡാക്ക് ലക്ഷ്യമിട്ട് അതിഗംഭീര ലൈറ്റ് ബാറ്റിൽ ടാങ്ക്",
      lead:
        "300 ടാങ്കുകളും 8700 കവചിത വാഹനങ്ങളുമുള്ള സൈന്യമാണ് ഇന്ത്യയുടേത്. ഒരു കോട്ട പോലെ അടച്ചുറപ്പുള്ള പ്രതിരോധ നിര. ഈ നിരയിലേക്ക് കൂടുതൽ കരുത്തുമായി എത്താനൊരുങ്ങുകയാണ് സൊറാവർ. രാജ്യം തദ്ദേശീയമായി വികസിപ്പിക്കുന്ന ലൈറ്റ് ബാറ്റിൽ ടാങ്ക്.",
      date: "March 04 , 2024 11:59 PM IST",
      country: "INDIA",
      image:
        "https://img-mm.manoramaonline.com/content/dam/mm/mo/technology/defense/images/2024/3/4/battle-tank.jpg",
    },
    {
      id: 4,
      title:
        "ആൽമരപ്പഴത്തിൽ ഒളിഞ്ഞിരുന്ന മഹാരഹസ്യം; തത്വമസിയെന്ന മഹാവാക്യം പിറന്ന കഥ",
      lead: "ഉപനിഷത്തുകളിൽ പറയുന്ന 4 മഹാവാക്യങ്ങളിൽ ഒന്നാണ് തത്വമസി",
      date: "March 07 , 2024 08:22 AM IST",
      country: "INDIA",
      image:
        "https://img-mm.manoramaonline.com/content/dam/mm/mo/astrology/spirituality/images/2024/3/7/Article.jpg",
    },
    {
      id: 5,
      title: "പാത്രത്തിന്റെ അടി കരിഞ്ഞോ? വൃത്തിയാക്കാന്‍ സവാള മാത്രം മതി",
      lead:
        "അടുപ്പില്‍ പാത്രം വച്ച് മറ്റു പണികള്‍ക്കു പോയാൽ തിരിച്ചുവരുമ്പോഴേക്കും അടി കരിഞ്ഞുപിടിച്ച് പാത്രം കളയാന്‍ തോന്നുന്ന രീതിയില്‍ ആയിട്ടുണ്ടാകും! കയ്യില്‍ കിട്ടിയ സകല സോപ്പും ഡിഷ്‌വാഷുമൊക്കെ ഇട്ട് പിന്നെയങ്ങോട്ട് യുദ്ധമാണ്.",
      date: "March 07 , 2024 08:22 AM IST",
      country: "INDIA",
      image:
        "https://img-mm.manoramaonline.com/content/dam/mm/mo/pachakam/features/images/2024/3/7/burned-pan.jpg",
    },
    {
      id: 6,
      title: "മഞ്ഞുമ്മൽ ബോയ്സ് അല്ല ഇത് മാമ്പ്ര ബോയ്സ്; യാത്രാനുഭവം",
      lead: "എല്ലാവർക്കും മഞ്ഞുമ്മൽ ബോയ്സിനെപ്പോലെയാകാൻ പറ്റില്ല",
      date: "March 07 , 2024 08:22 AM IST",
      country: "INDIA",
      image:
        "https://img-mm.manoramaonline.com/content/dam/mm/mo/travel/travel-news/images/2024/3/5/sanal-mruthul-arun-ratheesh-sarath-denin.jpg",
    },
  ];
  return (
    <div>
      <div className="bg-dark">
        <NavBar />
      </div>
      {/* <section style={{ backgroundColor: "#e6e7ea" }}>
        <Container>
          <Row>
            <Col lg={9}>
              <Row className="g-0 text-start">
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h4 className="pt-3 ms-2 fw-bolder">TOP NEWS</h4>
                  <div className="d-flex mt-3 flex-wrap">
                    {console.log(events)}
                    {events.map((event) => (
                      <div
                        className="card mb-3 ms-2 border-white rounded-0"
                        key={event.id}
                      >
                        <div className="row g-0">
                          <div className="col-md-4">
                            <img
                              src={event.image}
                              className="img-fluid p-2"
                              alt="..."
                            />
                          </div>
                          <div className="col-md-8">
                            <div className="card-body">
                              <h6 className="card-title fw-bold">
                                {event.title}
                              </h6>
                              <small className="card-text text-muted">
                                {event.lead}
                              </small>
                              <div className="row g-0">
                                <div className="col">
                                  <div>
                                    <small className="text-muted">
                                      {event.date}
                                    </small>
                                    ...
                                    <small className="text-primary">
                                      {event.country}
                                    </small>
                                  </div>
                                </div>
                                <div className="col text-end">
                                  <Link
                                    to={`${event.id}`}
                                    className="btn btn-dark btn-sm"
                                  >
                                    Read More
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={3}></Col>
          </Row>
        </Container>
      </section> */}
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Card, Col, Container, Image, NavLink, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SERVER_DOMAIN } from "../constant/config";

const NewsCategory = () => {
  const { category } = useParams();
  const [newsData, setNewsData] = useState([]);
  const [AdsData, setAdsData] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    console.log("category from URL:", category);
    // Fetch the specific news item's details using the ID
    axios
      .get(`${SERVER_DOMAIN}/api/category/${category}`)
      .then((response) => {
        const newsEntries = response.data;
        const sortedNewsData = newsEntries.filter(entry => entry.NE_Status !== 'DEACTIVATE');
        setNewsData(sortedNewsData); // Set the fetched news item details to state
        console.log(sortedNewsData)
      })
      .catch((error) => {
        console.error("Error fetching news details:", error);
      });
      const fetchAdsAndSetupInterval = async () => {
        try {
          const response = await axios.get(`${SERVER_DOMAIN}/api/ads`);
          const adData = response.data;
          const filteredAdData = adData.filter(entry => entry.status !== 'INACTIVE' && (entry.place === 'CategoryPage' || entry.place === 'AllPages'));
          setAdsData(filteredAdData);
          console.log(filteredAdData)
          // Set interval to rotate ads every 5 seconds if there are ads available
          if (filteredAdData.length > 0) {
            const id = setInterval(() => {
              setCurrentAdIndex(prevIndex => (prevIndex + 1) % filteredAdData.length);
            }, 60000);
  
            // Store intervalId in state
            setIntervalId(id);
          }
        } catch (error) {
          console.error("Error fetching ads:", error);
        }
      };
  
      fetchAdsAndSetupInterval();
  
      // Cleanup function for interval
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
  }, [category]); // Dependency array with 'id' to re-fetch details when the ID changes

  // If news item details are still loading or not available, display a loading message
  if (!newsData) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={9}>
            <h5 className="fw-bold border-start border-4 p-2 bg-light bg-gradient">
              TOP NEWS
            </h5>
            <div>
              <div>
                {console.log(newsData)}
                {newsData.map((newsItem) => (
                  <NavLink key={newsItem.NE_ID} as={Link} to={`/${newsItem.NE_Category}/${newsItem.NE_ID}`}>
                    <div
                      className="card border-white rounded-0 bg-opacity-10 me-3 mb-3"
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                      }}
                      key={newsItem.id}
                    >
                      <div className="row g-0">
                        <div className="col-md-4">
                        {!newsItem.NE_YoutubeLink ? (
                          <img
                            src={newsItem.NE_Image}
                            className="img-fluid p-2"
                            style={{ minHeight: "200px" }}
                            alt="..."
                          />
                        ) : (
                          // <img
                          //   src={`http://img.youtube.com/vi/${extractVideoId(newsItem.NE_YoutubeLink)}/0.jpg`}
                          //   className="img-fluid p-2"
                          //   style={{ minHeight: "200px" }}
                          //   alt="YouTube Thumbnail"
                          // />
                          <div className="position-relative">
                            {/* <div className="blur-background" style={{ backgroundImage: `url(${newsItem.NE_Image})`, filter: "blur(5px)", position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}></div> */}
                            <img
                              src={newsItem.NE_Image}
                              className="img-fluid p-2"
                              style={{ minHeight: "200px", filter: "blur(4px)" }}
                              alt="..."
                            />
                            <div className="position-absolute top-50 start-50 translate-middle">
                              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="red" class="bi bi-youtube" viewBox="0 0 16 16">
                                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                              </svg>

                            </div>
                          </div>
                        )}
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                          <div class="row align-items-start">
                            <div class="col">
                              <p className="text-uppercase fw-bold text-primary" style={{ fontSize: "10px" }}>
                                {newsItem.NE_Country} | <span className="text-warning">{newsItem.NE_Category}</span></p>
                            </div>
                            <div class="col text-end">
                              <p className="text-uppercase fw-bold text-primary" style={{ fontSize: "10px" }}>{new Date(newsItem.NE_CreatedAt).toLocaleDateString()}</p>
                            </div>
                          </div>
                            <h6 className="card-title fw-bold">
                              {newsItem.NE_Title}
                            </h6>
                            <small
                              className="card-text text-muted"
                              style={{ lineHeight: "2px", fontSize: "13px" }}
                            >
                              {newsItem.NE_Lead}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ))}
              </div>
            </div>
          </Col>
          <Col lg={3} className="text-center d-md-block">
            <small className="text-center pb-2">Advertisement</small>
            <Image
              className="mb-2"
              src={AdsData[currentAdIndex]?.Ad_Image_Path}
              fluid
            />
            {/* <Image
              className=""
              src="https://tpc.googlesyndication.com/simgad/1392822534263548062"
              fluid
            /> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewsCategory;
